<template>
  <footer v-if="show" class="w-11 mt-7 mb-5 mx-auto">
    <div class="mt-6 print-none">
      <div class="grid">
        <div class="col-12 lg:col-3 px-5 relative text-left">
          <NuxtImg format="webp" src="/img/logo-full-560x155.webp"
                   alt="Blockproof - Une équipe RGPD à vos côtés" width="300px"
                   style="transform: translateX(-15px);"
                   @click="showResetInput = true;"/>

          <div class="line-height-2 text-sm">
            Blockproof est un cabinet de conseil RGPD qui propose des accompagnements DPO externe, des formations, du
            support juridique, et des outils RGPD pour aider votre organisme à gérer les questions relatives à la
            protection des données.
          </div>

          <div class="mt-4">
            <PrimeButton label="Nous contacter" raised size="small"
                         class="text-sm lg:text-base ga-button-contact"
                         @click="$emitter.emit('contactModalEvent', {show: true})"/>
          </div>
        </div>

        <div class="col-12 lg:col-3 px-5">
          <h3 class="text-lg font-bold my-1">Ressources utiles</h3>

          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/blog/dpo-rgpd/" target="_blank">
              DPO RGPD : le guide complet
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/blog/dpo-externe-tarif/" target="_blank">
              Tarifs DPO externe sur le marché
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/blog/dpo-interne-ou-dpo-externe/" target="_blank">
              DPO interne ou DPO externe ?
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/blog/rgpd-prestataires/" target="_blank">
              Prestataires RGPD : qui sont-ils ?
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/blog/sanctions-rgpd-entreprises-associations/"
               target="_blank">
              5 sanctions RGPD incontournables
            </a>
          </div>
        </div>

        <div class="col-12 lg:col-3 px-5">
          <h3 class="text-lg font-bold my-1">Cas clients</h3>

          <div>
            <a class="text-800" href="https://blockproof.fr/blog/avis-client-dpo-externe-blockproof-blabfrance/"
               target="_blank">
              Direction générale
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="" target="_blank">
              Responsable Administrative et Financière
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/blog/avis-dpo-externe-medico-social/"
               target="_blank">
              RSI organisme médico-social
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href=""
               target="_blank">
              Responsable des opérations
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800"
               href="https://blockproof.fr/blog/avis-client-logiciel-rgpd/" target="_blank">Archiviste</a>
          </div>
          <div class="mt-2">
            <a class="text-800"
               href="https://blockproof.fr/blog/rgpd-blockproof-testimonial-anne-marie-nestier/"
               target="_blank">
              Consultant indépendante</a>
          </div>
        </div>

        <div class="col-12 lg:col-3 px-5">
          <h3 class="text-lg font-bold my-1">Liens</h3>

          <div class="mt-2">
            <a class="text-800" href="https://www.linkedin.com/company/blockproof/" rel="external" target="_blank">
              LinkedIn
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://podcast.ausha.co/in-my-data/" rel="external" target="_blank">
              Podcast RGPD
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/formations/" target="_blank">Formations</a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/privacy/" target="_blank">
              Conditions Générales d'Utilisation
            </a>
          </div>
          <div class="mt-2">
            <a class="text-800" href="https://blockproof.fr/data-protection/" target="_blank">
              Politique de protection des données
            </a>
          </div>
        </div>

      </div>
    </div>

    <!--    <div class="mt-6 py-4 text-center text-sm print-none">-->
    <!--      &lt;!&ndash;      <span class="mx-5">© 2019 Block I.T</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <br class="lg:hidden">&ndash;&gt;-->
    <!--      <NuxtLink to="/privacy" class="mx-5">-->
    <!--        <span class="text-900">Conditions Générales d'Utilisation</span>-->
    <!--      </NuxtLink>-->
    <!--      <br class="lg:hidden">-->
    <!--      <NuxtLink to="/data-protection" class="mx-5">-->
    <!--        <span class="text-900">Politique de protection des données</span>-->
    <!--      </NuxtLink>-->
    <!--    </div>-->

    <!--    <div class="mt-4">-->
    <!--      <div class="mx-auto w-9 border-bottom-2 border-blue-600"></div>-->
    <!--    </div>-->

    <!--    <div class="my-4 flex justify-content-center text-sm">-->
    <!--      <NuxtLink to="/privacy" class="mx-5">-->
    <!--        <span class="text-900">Conditions Générales d'Utilisation</span>-->
    <!--      </NuxtLink>-->
    <!--      <NuxtLink to="/data-protection" class="mx-5">-->
    <!--        <span class="text-900">Politique de protection des données</span>-->
    <!--      </NuxtLink>-->
    <!--    </div>-->

  </footer>
</template>


<script setup lang="ts">
import {useRuntimeConfig} from '#app';
import {useNuxtApp} from '#imports';
import {ref} from '@vue/reactivity';
import {MenuItem} from "primevue/menuitem";


const runtimeConfig = useRuntimeConfig();

let show = ref(true);
let showResetInput = ref(false);

let formEmail = '';

const resourcesItems: MenuItem[] = [
  {
    label: 'Blog RGPD',
    route: '/blog'
  },
  {
    label: 'Formations',
    route: '/formations'
  },
  {
    label: 'Podcast',
    url: 'https://podcast.ausha.co/in-my-data',
    target: 'blank'
  }
];


const resetDemoRegister = async () => {
  try {
    const response = await $fetch(runtimeConfig.public.apiBase + '/api/reset', {
      method: 'POST',
      body: {email: formEmail}
    });

    showResetInput.value = false;
    formEmail = '';
  } catch (error) {
    console.error(error); // TODO
    window.alert('Une erreur est survenue, veuillez essayez de nouveau.')
  }
}

// ## Event
const {$emitter} = useNuxtApp();

interface ShowFooterEvent {
  show: boolean
}

$emitter.on('showFooterEvent', (event: ShowFooterEvent) => show.value = event.show);
</script>


<style lang="scss" scoped>
</style>
